import * as React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import PrivacyPolicy from './PrivacyPolicy';
import Home from './Home';
import Contact from './Contact';
import IOSApp from './IOSApp';
import AboutUs from './AboutUs';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/ios_app" element={<IOSApp />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
    // <div className="App">
    //   <Header/>
    // </div>
  );
}

export default App;
