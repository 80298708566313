import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const pages = ["IOS App", "Contact", "About Us", "Privacy Policy"];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
        <ListItemButton onClick={() => navigate("/ios_app")}>
            <ListItemText>
              IOS App
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/contact")}>
            <ListItemText>
              Contact
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/about_us")}>
            <ListItemText>
              About Us
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/privacy_policy")}>
            <ListItemText>
              Privacy Policy
            </ListItemText>
          </ListItemButton>


          {/* {pages.map((page, index) => (
            <ListItemButton key={index}>
              <ListItemIcon>
                <ListItemText>{page}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))} */}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;