import React from "react";
import Header from './Header';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CssBaseline } from '@mui/material';

const Contact = (props) => {
    return (
        <div>
            <CssBaseline />
            <Container maxWidth="sm">
                <Box sx={{ mt: 12, width: '100%' }}>
                    <Header />
                    <h1>Contact</h1>
                    <p>Foresight Code LLC</p>
                    <p>draytonlav@gmail.com</p>
                </Box>
            </Container>
        </div>
    )
}

export default Contact;