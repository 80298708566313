import React from "react";
import Header from './Header';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CssBaseline } from '@mui/material';
import Link from '@mui/material/Link';
import Button from 'react-bootstrap/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';

const IOSApp = (props) => {
    return (
        <div>
            <Header />

            <CssBaseline />
            <Container maxWidth="sm">
                <Box sx={{ mt: 12, width: '100%' }}>
                    <Card sx={{ maxWidth: '100%'}}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="75%"
                            image="../Apple iPhone 11 Pro Max (1242x2688)/Apple iPhone 11 Pro Max Screenshot 0.png"
                            alt="Screenshot 1"
                            />
                            <CardActions>
                                <h1>Get it in the App Store</h1>
                                <Button variant="light"><Link href="https://tinyurl.com/keen-martial-arts">App Store</Link></Button>{' '}
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Box>
                <Box sx={{width: '100%' }}>
                    <Card sx={{ maxWidth: '100%'}}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="75%"
                            image="../Apple iPhone 11 Pro Max (1242x2688)/Apple iPhone 11 Pro Max Screenshot 3.png"
                            alt="Screenshot 2"
                            />
                            <CardActions>
                                <h1>Get it in the App Store</h1>
                                <Button variant="light"><Link href="https://tinyurl.com/keen-martial-arts">App Store</Link></Button>{' '}
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Box>
                <Box sx={{width: '100%' }}>
                    <Card sx={{ maxWidth: '100%'}}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="75%"
                            image="../Apple iPhone 11 Pro Max (1242x2688)/Apple iPhone 11 Pro Max Screenshot 4.png"
                            alt="Screenshot 3"
                            />
                            <CardActions>
                                <h1>Get it in the App Store</h1>
                                <Button variant="light"><Link href="https://tinyurl.com/keen-martial-arts">App Store</Link></Button>{' '}
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Box>
                <Box sx={{mb: 12, width: '100%' }}>
                    <Card sx={{ maxWidth: '100%'}}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="75%"
                            image="../Apple iPhone 11 Pro Max (1242x2688)/Apple iPhone 11 Pro Max Screenshot 5.png"
                            alt="Screenshot 4"
                            />
                            <CardActions>
                                <h1>Get it in the App Store</h1>
                                <Button variant="light"><Link href="https://tinyurl.com/keen-martial-arts">App Store</Link></Button>{' '}
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Box>
            </Container>
        </div>
    )
}

export default IOSApp;